<template>
    <div v-if="provisioningConfig?.threeLeggedOauthRequired()" class="text-bold text-body-2">
        Make sure your browser pop-ups are not blocked
    </div>
</template>

<script>
import ProvisioningConfig from "@/lib/application/provisioning_config"

export default {
    props: {
        provisioningConfig: {
            type: ProvisioningConfig,
        }
    },
}
</script>