<template>
    <AlertBanner type="info-no-bg">
        Having issues integrating your application?
        <a href="https://scribehow.com/page/App_API_Integrations__yUkIBDENTu2_VuwSdvLT5A" target="_blank">Check out our docs</a> or
        <a href="https://join.slack.com/t/yeshid-community/shared_invite/zt-23j3mkjc6-Ic562ZO~9EGNP79EGBSM3Q" target="_blank">
            write to us here!
        </a>
    </AlertBanner>
</template>

<script>
import AlertBanner from "@/components/AlertBanner.vue";

export default {
    components: {
        AlertBanner,
    },
}
</script>