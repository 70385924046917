<template>
    <BaseDialog :title="currentPlan.dialogTitle" :show.sync="dialogToggle" :width="550" :close="isExpired ? close : null">
        <template slot="content">
            <div class="dialog-gradient grid-layout-column grid-gap-xxl" style="margin: -24px; padding: 24px;">
                <div class="grid-layout-column grid-gap-lg">
                    <h5 class="mb-2 blue-text--text" style="white-space: pre-wrap;">{{ isExpired ? currentPlan.expiredContentTitle : currentPlan.contentTitle }}</h5>
                    <div v-for="f in currentPlan.features" :key="f.name">
                        <div class="grid-layout grid-basis-auto align-center">
                            <v-icon v-if="f.type === 'warn'" color="warning">mdi-information-box-outline</v-icon>
                            <v-icon v-else color="success-icon">mdi-check</v-icon>
                            <b :class="f.type === 'warn' ? 'feature--warn' : 'feature'">{{ f.name }}</b>
                            <template v-if="f.detail">
                                •
                                <span>{{ f.detail }}</span>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="grid-layout-column blue-text--text">
                    <template v-if="planId === 'pro_yearly_v2' && checkFeatureFlag('lighthouse-customer')">
                        <SubscribeButton @click="goToPay" :disabled="loading" :loading="loading" height="48px"/>
                        <v-btn color="blue-text" text height="48px" @click="letsGetStarted" :loading="loading" :disabled="loading">I'll do this later</v-btn>
                    </template>
                    <v-btn v-else color="primary" @click="letsGetStarted" elevation="0" :loading="loading" :disabled="loading" height="48px">Get started!</v-btn>
                    <div class="text-body-2 text-center">
                        Continuing use of YeshID indicates your acceptance of our
                        <a class="blue-text--text text-decoration-underline" href="https://yeshid.com/privacy-policy/" target="_blank">Privacy Policy</a>
                        and
                        <a class="blue-text--text text-decoration-underline" href="https://yeshid.com/terms-and-conditions/" target="_blank">Terms of Service</a>.
                    </div>
                </div>
            </div>
        </template>

        <template v-if="error" slot="error">
            {{ error }}
        </template>

    </BaseDialog>
</template>

<script>

import BaseDialog from '@/components/dialog/BaseDialog.vue'
import SubscribeButton from "@/views/Manage/Billing/SubscribeButton.vue";
import { mapGetters } from 'vuex'

export const MAX_FREE_SEATS = 30
export const FREE_PLAN = "free_yearly"
export const PRO_PLAN = "pro_yearly_v2"
export const plans = {
    [FREE_PLAN]: {
        dialogTitle: "YeshID is free for small teams",
        contentTitle: "Here’s what’s included:",
        features: [{
            name: "Up to 30 active users",
        }, {
            name: "Unlimited onboarding and offboarding workflows",
        }, {
            name: "Integration with Slack"
        }, {
            name: "Access request and review management"
        }, {
            name: "Support via email and in our community Slack channel"
        }],
    },
    [PRO_PLAN]: {
        dialogTitle: "You qualify for a 14-day free trial!",
        contentTitle: "Here’s what’s included in Pro:",
        expiredContentTitle: "Your trial has expired -- pay now to get the most out of YeshID",
        features: [{
            name: "Up to 500 active users",
        }, {
            name: "Unlimited onboarding and offboarding workflows",
        }, {
            name: "Integration with Slack",
        }, {
            name: "Access request and review management",
        }, {
            name: "Support via email and in our community Slack channel"
        }]
    },
}

export default {
    components: {
        SubscribeButton,
        BaseDialog
    },
    props: {
        toggle: {
            type: Boolean,
            default: false
        },
        plan: {
            type: Object,
            default: Object,
        },
        isExpired: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            loading: false,
            error: "",
        }
    },
    mounted() {
        // TODO: remove this after lighthouse customers are onboarded
        if (this.checkFeatureFlag('lighthouse-customer')) {
            plans[PRO_PLAN].dialogTitle = "Start your free year of YeshID Pro"
            plans[PRO_PLAN].contentTitle = "As thanks for your support,\nget started with a free year of YeshID Pro."
            plans[PRO_PLAN].features.push({
                type: "warn",
                name: "Free year expires: Oct 15, 2025",
            })
        }
    },
    computed: {
        ...mapGetters({
            checkFeatureFlag: "checkFeatureFlag",
        }),
        dialogToggle: {
            get() {
                return this.toggle;
            },
            set(value) {
                this.$emit("update:toggle", value);
            },
        },
        currentPlan() {
            return plans[this.planId]
        },
        planId() {
            return this.plan.billableSeats && this.plan.billableSeats > MAX_FREE_SEATS ? PRO_PLAN : FREE_PLAN
        }
    },
    methods: {
        close() {
            this.dialogToggle = false
        },
        goToPay() {
            this.$router.push(`/manage/billing/purchase`)
            this.close()
        },
        createSubscription(planId) {
            this.loading = true
            this.error = ""

            this.$http.post("/api/v1/billing/subscription", { plan: planId }).then(response => {
                this.$emit("subscribed", response.data)
            }).catch(() => {
                this.$emit("toast", "We could not create your subscription, go ahead and continue using YeshID!", "error")
            }).finally(() => {
                this.loading = false
                if (!this.error) {
                    this.dialogToggle = false
                }
            })
        },
        letsGetStarted() {
            if (this.planId === PRO_PLAN) {
                this.createProTrialSubscription()
            } else {
                this.createFreeSubscription()
            }
        },
        createFreeSubscription() {
            this.createSubscription(FREE_PLAN)
        },
        createProTrialSubscription() {
            this.createSubscription(PRO_PLAN)
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog-gradient {
    background: linear-gradient(180deg, #EBF6FF 0%, #F6FBFF 100%);
}
.feature {
    color: $blue-text;
}

.theme--dark .dialog-gradient {
    background: linear-gradient(180deg, #213547 0%, #222325 100%);
}
.theme--dark .feature {
     color: $blue-text--dark;
}
</style>