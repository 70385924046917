<template>
    <v-form ref="restApiConfig" class="grid-layout-column grid-gap-xxl">
        <FormSectionDescription v-if="title" :title="title"/>
        <div v-if="!onlyAuth" class="grid-layout grid-gap-lg">
            <!-- Method -->
            <v-sheet class="grid-layout-column" max-width="120px">
                <FormSelect
                    v-model="form.method"
                    :items="['GET', 'POST', 'PUT', 'DELETE']"
                    label="Method"
                    @change="updateForm"
                />
            </v-sheet>

            <!-- Endpoint URL -->
            <div class="grid-layout-column">
                <FormTextInput
                    v-model="form.endpoint"
                    label="Endpoint URL"
                    placeholder="https://"
                    :rules="[ruleRequired, ruleUrl]"
                    @change="updateForm"
                    showValid
                    info="Enter the full URL of the endpoint that provides your team's users. Include any team or organization IDs in the path."
                />
            </div>
        </div>

        <template v-if="validUrl || onlyAuth">
            <v-divider v-if="!onlyAuth"/>

            <ExpandableSection v-if="validUrl && !onlyAuth" :isOpenByDefault="showAdvanced" iconColor="text-secondary">
                <template #header>
                    <b class="text-body-2">Optional configuration</b>
                </template>
                <template slot="content">
                    <div class="mt-4 pl-6 grid-layout-column grid-gap-xl">
                        <!-- URL parameters key/value pairs -->
                        <div class="grid-layout-column grid-gap-sm">
                            <div class="d-flex align-center">
                                <b class="text-body-2">URL Parameters</b>
                                <InfoTooltip class="ml-1" text="Parameters are added after the URL typically in this form: '?key1=value1,key2=value2'." top/>
                            </div>
                            <div v-for="(_, index) in form.urlParameters" :key="index" class="grid-layout align-center">
                                <FormTextInput
                                    v-model="form.urlParameters[index].key"
                                    placeholder="Key"
                                    @change="updateForm"
                                />
                                <FormTextInput
                                    v-model="form.urlParameters[index].value"
                                    placeholder="Value"
                                    @change="updateForm"
                                />
                                <v-icon class="grid-child-auto" color="error-icon" @click="form.urlParameters.splice(index, 1)">mdi-trash-can-outline</v-icon>
                            </div>
                            <a class="text-body-2 text-bold" @click="form.urlParameters.push(newKeyValue())">Add more</a>
                        </div>

                        <!-- Headers -->
                        <div class="grid-layout-column grid-gap-sm">
                            <div class="d-flex align-center">
                                <b class="text-body-2">Headers</b>
                                <InfoTooltip class="ml-1" text="This section transfers metadata about the API request and the desired response." top/>
                            </div>
                            <div v-for="(_, index) in form.headers" :key="index" class="grid-layout align-center">
                                <FormTextInput
                                    v-model="form.headers[index].key"
                                    placeholder="Key"
                                    @change="updateForm"
                                />
                                <FormTextInput
                                    v-model="form.headers[index].value"
                                    placeholder="Value"
                                    @change="updateForm"
                                />
                                <v-icon class="grid-child-auto" color="error-icon" @click="form.headers.splice(index, 1)">mdi-trash-can-outline</v-icon>
                            </div>
                            <a class="text-body-2 text-bold" @click="form.headers.push(newKeyValue())">Add more</a>
                        </div>

                        <!-- post body -->
                        <div class="grid-layout-column" v-if="form.method === 'POST' || form.method === 'PUT'">
                            <b class="text-body-2">Body</b>
                            <v-textarea
                                v-model="form.body"
                                outlined
                                rows="5"
                                auto-grow
                                clearable
                                @change="updateForm"
                                info="Data to send to the API."
                            />
                        </div>
                    </div>
                </template>
            </ExpandableSection>

            <v-divider v-if="!onlyAuth"/>

            <!-- Authentication: Bearer Token, OAuth2, None -->
            <div class="grid-layout-column grid-gap-lg">
                <b v-if="!supportedAuthTypes.length === 1 || supportedAuthTypes[0].value !== ''">Authentication</b>
                <v-item-group v-if="supportedAuthTypes.length > 1" class="grid-layout grid-basis-sm grid-gap-lg" v-model="form.auth.type">
                    <template v-for="auth of supportedAuthTypes">
                        <v-item
                            v-slot="{ active, toggle }"
                            :key="auth.value"
                            :value="auth.value"
                        >
                            <v-card class="text-bold text-secondary--text text-center align-content-center" :class="active ? 'selected-auth' : ''"
                                     height="50px"
                                    outlined rounded="lg" :ripple="false"
                                    @click="toggle($event); updateForm();">
                                {{ auth.text }}
                            </v-card>
                        </v-item>
                    </template>
                </v-item-group>

                <!-- based on the selected auth type, show the appropriate fields -->
                <div v-if="form.auth.type === 'bearer'">
                    <FormTextInput
                        v-model="form.auth.bearerConfig.token"
                        label="Token"
                        :rules="[ruleRequired]"
                        type="password"
                        @change="updateForm"
                        info="Bearer authentication sends the provided token in the headers of a request."
                    />
                </div>

                <div v-else-if="form.auth.type === 'oauth2'"
                     class="grid-layout-column grid-gap-lg">
                    <FormSelect
                        v-model="form.auth.oauth2Config.grantType"
                        :items="[{text: 'Authorization Code Grant', value: 'authorizationCode'}, {text: 'Client Credentials', value: 'clientCredentials'}, {text: 'Refresh Token', value: 'refreshToken'}]"
                        @change="updateForm"
                        info="The method to get tokens for making requests to the API."
                    />
                    <FormTextInput v-if="form.auth.oauth2Config.grantType === 'authorizationCode'"
                                   v-model="form.auth.oauth2Config.authUrl"
                                   label="Authorization URL"
                                   placeholder="https://"
                                   :rules="[ruleRequired, ruleUrl]"
                                   showValid
                                   @change="updateForm"
                                   info="The URL where you'll login to the application to authorize YeshID to generate a token."
                    />
                    <FormTextInput
                        v-model="form.auth.oauth2Config.tokenUrl"
                        label="Access Token URL"
                        placeholder="https://"
                        :rules="[ruleRequired, ruleUrl]"
                        showValid
                        @change="updateForm"
                        info="This is the URL YeshID will request a token from the application's API."
                    />
                    <FormTextInput
                        v-model="form.auth.oauth2Config.clientId"
                        label="Client ID"
                        :rules="[ruleRequired]"
                        @change="updateForm"
                        info="A unique identifier for your account, or organization, or in some cases a private integration you have to set up."
                    />
                    <FormTextInput
                        v-model="form.auth.oauth2Config.clientSecret"
                        label="Client Secret"
                        :rules="[ruleRequired]"
                        type="password"
                        @change="updateForm"
                        info="The secret required with the client ID to generate a token."
                    />
                    <FormTextInput
                        v-if="form.auth.oauth2Config.grantType === 'refreshToken'"
                        v-model="form.auth.oauth2Config.token"
                        label="Refresh Token"
                        :rules="[ruleRequired]"
                        type="password"
                        @change="updateForm"
                        info="Refresh token used to fetch an access token."
                    />
                    <FormTextInput
                        v-model="form.auth.oauth2Config.scopes"
                        label="Scopes"
                        @change="updateForm"
                        info="The permissions you're providing YeshID when accessing the API."
                    />
                    <!-- Two values below technically do nothing at the moment -->
                    <FormSelect
                        v-if="form.auth.oauth2Config.grantType === 'authorizationCode'"
                        v-model="form.auth.oauth2Config.prompt"
                        :items="[{text: 'None', value: 'NONE'}, {text: 'Login', value: 'LOGIN'}, {text: 'Consent', value: 'CONSENT'}, {text: 'Select Account', value: 'SELECT_ACCOUNT'}, {text: 'No Prompt', value: 'NO_PROMPT'}]"
                        label="Prompt"
                        @change="updateForm"
                    />
                    <FormTextInput
                        v-if="form.auth.oauth2Config.grantType !== 'refreshToken'"
                        v-model="form.auth.oauth2Config.audience"
                        label="Audience"
                        @change="updateForm"
                    />
                </div>

                <div v-else-if="form.auth.type === 'basic'"
                     class="grid-layout-column grid-gap-lg">
                    <FormTextInput
                        v-model="form.auth.basicConfig.username"
                        label="Username"
                        :rules="[ruleRequired]"
                        @change="updateForm"
                    />
                    <FormTextInput
                        v-model="form.auth.basicConfig.password"
                        label="Password"
                        :rules="[ruleRequired]"
                        type="password"
                        @change="updateForm"
                    />
                </div>
            </div>
        </template>
    </v-form>
</template>

<style scoped lang="scss">
.selected-auth {
    color: $text-default !important;
    border-color: $primary !important;
}
.v-card--link:focus:before {
    opacity: 0 !important;
}
</style>

<script>
import FormTextInput from "@/components/FormTextField.vue";
import FormSelect from "@/components/FormSelect.vue";
import FormSectionDescription from "@/components/FormSectionDescription.vue";
import ExpandableSection from '../ExpandableSection.vue';
import InfoTooltip from "@/components/InfoTooltip.vue";

export default {
    components: {
        InfoTooltip,
        FormSectionDescription,
        FormSelect,
        FormTextInput,
        ExpandableSection
    },
    props: {
        value: {
            type: Object,
            default: Object,
        },
        title: {
            type: String,
            default: "",
        },
        onlyAuth: {
            type: Boolean,
            default: false,
        },
        authTypes: {
            type: Array,
            default: Array,
        }
    },
    data() {
        const form = this.copyForm(this.value)
        const hasParameters = form.urlParameters.entries().some(p => p[0] && p[1])
        const hasHeaders = form.headers.entries().some(h => h[0] && h[1])

        return {
            showAdvanced: hasParameters || hasHeaders,
            showEntireForm: false,
            form,
        }
    },
    computed: {
        validUrl() {
            return this.form.endpoint && this.ruleUrl(this.form.endpoint) === true
        },
        supportedAuthTypes() {
            const types = [{text: 'Bearer', value: 'bearer'}, {text: 'OAuth2', value: 'oauth2'}, {text: 'Basic', value: 'basic'}, {text: 'None', value: ''}]
            if (!this.authTypes.length) {
                return types
            }

            return types.filter(t => this.authTypes.includes(t.value))
        }
    },
    mounted() {
        if (this.authTypes.length > 0) {
            if (this.value.auth.type) {
                this.form.auth.type = this.value.auth.type
            } else {
                this.form.auth.type = this.authTypes[0]
            }
            this.updateForm()
        }
    },
    methods: {
        copyForm(form = {}) {
            const auth = form.auth || {}
            const bearerConfig = auth.bearerConfig || []
            const oauth2Config = auth.oauth2Config || {}
            const basicConfig = auth.basicConfig || {}

            return {
                method: form.method || "GET",
                endpoint: form.endpoint || "",
                body: form.body || "",
                urlParameters: form.urlParameters || [this.newKeyValue()],
                headers: form.headers || [this.newKeyValue()],
                auth: {
                    type: auth.type || "",
                    bearerConfig: {
                        // Bearer
                        token: bearerConfig.token || "",
                    },
                    oauth2Config: {
                        // OAuth 2.0
                        grantType: oauth2Config.grantType || "authorizationCode",
                        authUrl: oauth2Config.authUrl || "",
                        token: oauth2Config.token || "",
                        tokenUrl: oauth2Config.tokenUrl || "",
                        clientId: oauth2Config.clientId || "",
                        clientSecret: oauth2Config.clientSecret || "",
                        scopes: oauth2Config.scopes || "",
                        prompt: oauth2Config.prompt || "",
                        audience: oauth2Config.audience || "",
                    },
                    basicConfig: {
                        // Basic
                        username: basicConfig.username || "",
                        password: basicConfig.password || "",
                    }
                }
            }
        },
        newKeyValue() {
            return { key: '', value: '' }
        },
        validate() {
            return this.$refs.restApiConfig.validate()
        },
        updateForm() {
            this.$emit("input", this.form)
        }
    },
    watch: {
        value(val) {
            this.form = this.copyForm(val)
            if (val?.auth?.type) {
                this.form.auth.type = val.auth.type
            } else {
                this.form.auth.type = this.authTypes[0]
            }
        },
        "form.endpoint": function() {
            if (this.validUrl) {
                this.showEntireForm = true
            }
        },
    },
}
</script>