<template>
    <v-row no-gutters class="flex-grow-0 flex-shrink-0">
        <v-col :align="align">
            <slot/>
            <div class="text-disabled--text text-caption">
                &copy; {{ new Date().getFullYear() }} YeshID, Inc. All rights reserved.
            </div>
            <div class="text-disabled--text text-caption">
                <a class="text-disabled--text text-decoration-underline" href="https://yeshid.com/privacy-policy/" target="_blank">Privacy Policy</a> and
                <a class="text-disabled--text text-decoration-underline" href="https://yeshid.com/terms-and-conditions/" target="_blank">Terms of Service</a>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        align: String,
    },
    computed: {
        classNames() {
            if (this.app) {
                return "pa-0 ma-4"
            }

            return "pa-0 ma-0"
        }
    }
}
</script>