<template>
    <v-container class="pa-0 fill-height text-center grid-layout-column grid-gap-none align-content-center justify-center overflow-y-hidden"
                 :class="isMobile ? 'text-body-2' : 'text-body-1'"
                 style="position: relative; background-color: transparent;"
                 :style="{ color: $vuetify.theme.currentTheme['new-brand-text']}"
                 fluid>
        <v-sheet ref="topHalf" class="grid-layout-column grid-gap-xxl align-center justify-center flex-grow-1"
                 width="100%" height="45%" :class="topHalfAnimation" style="z-index: 2;"
                 :color="$vuetify.theme.currentTheme['new-app-bg']" :dark="$vuetify.theme.options.darkNav" :style="{ color: $vuetify.theme.currentTheme['new-brand-text'] }">
            <BrandLogo class="ml-6 mt-6 align-self-start" height="28px" width="115px"/>
            <v-spacer class="flex-grow-1 flex-shrink-1"/>
            <div :class="isMobile ? 'text-h4' : 'text-h3'" style="letter-spacing: -1px !important;">{{title}}</div>
            <v-sheet class="d-flex justify-center mt-14" color="rgba(127, 127, 127, 0.05)" height="30px" width="100%"
                     style="border-top: 1px solid #5283b11a; border-bottom: 1px solid #5283b11a; position: relative">
                <v-img :src="require('@/assets/login/badge.png')" width="193px" height="236px" contain style="top: -48px; pointer-events: none;"/>
            </v-sheet>
        </v-sheet>
        <v-sheet class="grid-layout-column grid-gap-xxl align-center justify-center flex-grow-1"
                 width="100%" height="55%" :class="bottomHalfAnimation" style="z-index: 1;"
                 :color="$vuetify.theme.currentTheme['new-app-bg']" :dark="$vuetify.theme.options.darkNav" :style="{ color: $vuetify.theme.currentTheme['new-brand-text'] }">
            <v-sheet class="mb-14" color="rgba(127, 127, 127, 0.05)" height="30px" width="100%" style="border-top: 1px solid #5283b11a; border-bottom: 1px solid #5283b11a;"/>
            <slot name="activator" v-bind="{ on: { click }, isMobile }"/>
            <AlertBanner v-if="error" class="mx-4" type="error">{{this.error}}</AlertBanner>
            <div class="d-flex flex-column flex-grow-1">
                <slot name="details" v-bind="{ isMobile }"/>
                <SiteFooter class="mb-6 mt-2 flex-grow-1" align="center" style="align-items: end;">
                    <PoweredByYeshID class="mb-2" :dark="$vuetify.theme.options.darkNav"/>
                </SiteFooter>
            </div>
        </v-sheet>
        <div class="fill-height" style="position: absolute; width: 100%;">
            <slot name="reveal" v-bind="{ isMobile }"/>
        </div>
    </v-container>
</template>

<style scoped lang="scss">
.open-animation {
    transition: transform 0.7s cubic-bezier(0, 0, 0.2, 1);
}

.open-up {
    // the extra px translate is for the badge overlap
    transform: translateY(-100%) translateY(-118px);
}
.open-down {
    transform: translateY(100%);
}
</style>

<script>
import AlertBanner from '@/components/AlertBanner.vue';
import SiteFooter from "@/components/SiteFooter.vue";
import PoweredByYeshID from "@/components/PoweredByYeshID.vue";
import BrandLogo from "@/components/BrandLogo.vue";

export default {
    props: {
        title: {
            type: String,
        },
        error: {
            type: String,
        },
        open: {
            type: Boolean,
            default: false,
        }
    },
    mounted() {
        this.$refs.topHalf.$el.addEventListener('transitionend', this.onTransitionEnd)
    },
    beforeDestroy() {
        this.$refs.topHalf.$el.removeEventListener('transitionend', this.onTransitionEnd)
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.mobile
        },
        internalOpen: {
            get() {
                return this.open
            },
            set(open) {
                this.$emit('update:open', open)
            }
        },
        topHalfAnimation() {
            return this.internalOpen ? 'open-up open-animation' : 'open-animation'
        },
        bottomHalfAnimation() {
            return this.internalOpen ? 'open-down open-animation' : 'open-animation'
        }
    },
    methods: {
        click() {
            this.internalOpen = true
        },
        onTransitionEnd() {
            if (this.internalOpen) {
                this.$emit('afterOpen')
            } else {
                setTimeout(() => {
                    this.$emit('afterClose')
                }, 200)
            }
        }
    },
    components: {
        PoweredByYeshID,
        SiteFooter,
        AlertBanner,
        BrandLogo,
    }
}

</script>