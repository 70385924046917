import axios from "axios"
import ProvisioningConfig from "./provisioning_config";

// TODO: might need a catalog application type
class Application {
    constructor(id) {
        this.id = id
        this.orgId = ""
        this.catalogApplicationId = ""
        this.name = ""
        this.description = ""
        this.applicationType = ""
        this.domain = ""
        this.icon = null
        this.integrations = []
        this.administrators = []
        this.onboardingInstructions = ""
        this.offboardingInstructions = ""
        this.connectProvisioningInstructions = ""
        this.orgApplicationProvisioningConfig = null
        this.globalApplicationProvisioningConfig = null
        this.deleted = false
        this.lastImportedAt = null

        this.loading = false
        this.loaded = false
        this.error = ""
    }
    load() {
        this.loading = true
        this.loaded = false
        return axios.get(`/api/v1/application/${this.id}`).then(resp => {
            const app = resp?.data?.application || {}
            Object.assign(this, app)
            if (app.orgApplicationProvisioningConfig) {
                this.orgApplicationProvisioningConfig = new ProvisioningConfig(app.id, app.orgApplicationProvisioningConfig)
            }
            if (app.globalApplicationProvisioningConfig) {
                this.globalApplicationProvisioningConfig = new ProvisioningConfig(app.id, app.globalApplicationProvisioningConfig)
            }
            this.loaded = true
        }).catch(err => {
            this.error = err?.response?.data?.message
            console.log(err)
            return Promise.reject(this.error)
        }).finally(() => {
            this.loading = false
            return Promise.resolve()
        })
    }
    loadFromObject(obj) {
        // TODO: use in load()
        if (!obj.id) {
            return
        }
        Object.assign(this, obj)
        if (obj.orgApplicationProvisioningConfig) {
            this.orgApplicationProvisioningConfig = new ProvisioningConfig(obj.id, obj.orgApplicationProvisioningConfig)
        }
        if (obj.globalApplicationProvisioningConfig) {
            this.globalApplicationProvisioningConfig = new ProvisioningConfig(obj.id, obj.globalApplicationProvisioningConfig)
        }
    }
    getName() {
        return !this.name ? this.domain : this.name
    }
    getProvisioningConfig() {
        return this.orgApplicationProvisioningConfig || this.globalApplicationProvisioningConfig
    }
    isConnected() {
        return this.orgApplicationProvisioningConfig && this?.orgApplicationProvisioningConfig?.enabled
    }
    isSyncing() {
        return this.orgApplicationProvisioningConfig && this.orgApplicationProvisioningConfig.status === 'started'
    }
    provisionerIsAuthorized() {
        return this.getProvisioningConfig()?.isAuthorized
    }
    isCustomProvisioner() {
        return this.getProvisioningConfig()?.provisioningType === "custom"
    }
    getLastImportedAt() {
        return this.lastImportedAt
    }
    runSync() {
        const config = this.getProvisioningConfig()
        if (!config || !config.enabled) {
            return
        }

        return config.startSync().catch(error => {
            this.error = error?.response?.data?.message
            return Promise.reject(this.error)
        }).finally(() => {
            return Promise.resolve()
        })
    }
}

export default Application