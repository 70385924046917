<template>
    <div>
        <v-layout class="pb-4" align="end">
            <v-spacer></v-spacer>
            <TableSearchBar :search.sync="search" />
            <RefreshTable @click="getApplications" />
            <ReorderTableHeader :tableHeaders.sync="headers" tableName="applications"/>
            <TableMenu :tableHeaders.sync="headers" :tableData="applications" csvFilename="applications" />
        </v-layout>

        <FilterBar class="mb-4" :filters.sync="applications.filters" :filterComponents="filterComponents" @applied="getApplications"/>
        <v-data-table :headers="headers" :items="filteredApplications" :search="search" :loading="loading" min-width="1000px">
            <template v-for="h in headers" v-slot:[`header.${h.value}`]>
                <v-tooltip :key="h.value" v-if="h.tooltip" top max-width="300px">
                    <template v-slot:activator="{ on }">
                        <span v-on="on">{{ h.text }} <v-icon class="mx-1" size="16px" color="grey">mdi-information</v-icon></span>
                    </template>
                    <span>{{ h.tooltip }}</span>
                </v-tooltip>
                <span :key="h.value + '-else'" v-else>{{ h.text }}</span>
            </template>

            <template v-slot:[`header.name`]="{ header }">
                <ApplicationHeader :header="header"/>
            </template>

            <template #[`item.name`]="{ item }">
                <ApplicationNameCell :application="item" @onclick="showPanel(item, item.applicationId ? 'details' : 'users')" cols="8"/>
            </template>

            <template #[`item.numberOfUsers`]="{ item }">
                <div><a @click="showPanel(item, 'users')">{{ item.numberOfUsers }}</a></div>
            </template>

            <template #[`item.scopes`]="{ item }">
                <SecurityScopesCell :item="item" @click="showPanel(item, 'scopes')"/>
            </template>

            <template #[`item.managed`]="{ item }">
                <v-chip v-if="item.managed" color="success" small>Yes</v-chip>
                <v-chip v-else small>No</v-chip>
            </template>
        </v-data-table>
        <SecuritySidePanel @grantRevoked="refresh" @appWasAdded="appWasAdded" :application.sync="selectedApplication" :tab.sync="selectedTab" :visible.sync="panelVisible"/>
    </div>
</template>


<script>
import TableMenu from '../../components/TableMenu.vue'
import ApplicationNameCell from '@/components/table/ApplicationNameCell.vue'
import SecurityScopesCell from './SecurityScopesCell.vue'
import SecuritySidePanel from './SecuritySidePanel.vue';
import ApplicationHeader from '@/components/table/ApplicationHeader.vue';
import ReorderTableHeader from '@/components/ReorderTableHeader.vue';
import RefreshTable from '@/components/RefreshTable.vue'
import TableSearchBar from '@/components/TableSearchBar.vue';
import FilterBar from '@/components/filters/FilterBar.vue';
import SecurityApplicationsFilter from './SecurityApplicationsFilter.vue';
import { DateTime } from 'luxon';

export default {
    components: {
    TableMenu,
    ApplicationNameCell,
    SecurityScopesCell,
    ReorderTableHeader,
    RefreshTable,
    TableSearchBar,
    ApplicationHeader,
    FilterBar,
    SecuritySidePanel
},
    props: {
        scopeCategoryFilter: {
            type: Array,
            default: () => ["all"],
        },
        scopeFilter: {
            type: String,
            default: '',
        },
        selectedAppGroup: {
            type: String,
            default: ''
        }
    },
    mounted() {
        this.getApplications()

        this.$root.$on('syncedWithGoogle', () => {
            this.getApplications()
        });
    },
    beforeDestroy() {
        this.$root.$off('syncedWithGoogle')
    },
    computed: {
        filteredApplications() {
            let apps = this.applications

            if (this.selectedAppGroup === "forbidden") {
                apps = apps.filter(a => a.forbidden)
            }

            if (this.selectedAppGroup === "new") {
                apps = apps.filter(a => a.createdAt && DateTime.now().diff(DateTime.fromISO(a.createdAt),"days").as("days") <= 7)
            }

            if (this.scopeFilter) {
                const scopeFilterLower = this.scopeFilter.toLocaleLowerCase()
                apps = apps.filter(a => {
                    if (a.name.toLocaleLowerCase().includes(scopeFilterLower)) {
                        return true
                    }
                    return a.scopes.some(s => s.name.toLocaleLowerCase().includes(scopeFilterLower))
                })
            }

            if (!this.scopeCategoryFilter.includes('all')) {
                apps = apps.filter(a => {
                    let r = false
                    a.scopes.forEach(s => {
                        if (this.scopeCategoryFilter.includes(this.scopes[s.name].category.toLowerCase())) {
                            r = true
                        }
                    })
                    return r
                })
            }

            return apps
        },
    },
    data() {
        return {
            viewHeader: {
                title: "Security",
                info: "This list shows the 3rd party applications and services accessed by your employees using your company's Google credentials via OAuth.",
            },
            applications: [],
            filterComponents: [
                SecurityApplicationsFilter,
            ],
            panelVisible: false,
            selectedApplication: {},
            selectedTab: 'details',
            search: '',
            loading: false,
            headers: [{
                text: 'Application',
                sortable: true,
                value: 'name'
            }, {
                text: 'Users',
                sortable: true,
                value: 'numberOfUsers',
            }, {
                text: 'Scopes',
                sortable: false,
                value: 'scopes',
                textFormatter: (s) => s.map(x => x.name).join("|"),
            }, {
                text: 'Managed',
                sortable: true,
                value: 'managed',
            }
            
            ],
        }
    },
    methods: {
        getApplications() {
            this.loading = true
            let filter = ''
            if (this.applications.filters) {
                filter = this.$api.filters.buildFilterParam(this.applications.filters)
            }
            const parameters = filter ? `?filter=${filter}` : ''
            this.$http.get('/api/v1/applications/thirdparty' + parameters).then(resp => {
                this.applications = resp.data.applications
                this.scopes = resp.data.scopes
                for (const app of this.applications) {
                    app.scopes = app.scopes.map(s => ({ name: s, category: this.scopes[s].category, description: this.scopes[s].description }))
                }
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                this.loading = false
            })
        },
        appWasAdded(app) {
            app.managed = true
        },
        showPanel(application, tab) {
            this.selectedApplication = application;
            this.selectedTab = tab;
            this.panelVisible = true;
        },
        refresh() {
            this.getApplications()
        },
    },
    metaInfo: {
        title: 'Security - YeshID'
    }
}

</script>
