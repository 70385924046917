import axios from "axios"

const SECRET_VALUE = "-- Secret Value --"

class ProvisioningConfig {
    constructor(appId = "", config = {}) {
        this.appId = appId
        this.provisioningType = config?.provisioningType || ""
        this.baseUrl = config?.baseUrl || ""
        this.testEmail = config?.testEmail || ""
        this.createUsers = config?.createUsers || false
        this.updateUsers = config?.updateUsers || false
        this.deactivateUsers = config?.deactivateUsers || false
        this.importUsers = config?.importUsers || false
        this.enabled = config?.enabled || false
        this.isAuthorized = config?.isAuthorized || false
        this.authType = config?.authType || ""
        this.authConfigBearer = config?.authConfigBearer || null
        this.authConfigOAuth2 = config?.authConfigOAuth2 || null
        this.authConfigApiKey = config?.authConfigApiKey || null
        this.authConfigBasic = config?.authConfigBasic || null
        this.authConfigDirectory = config?.authConfigDirectory || null
        this.optionsConfig = config?.optionsConfig || {
            createUsers: false,
            updateUsers: false,
            deactivateUsers: false,
            importUsers: false,
        }
        this.importUsersMetadata = config?.importUsersMetadata || {
            method: "",
            endpoint: "",
            urlParameters: [],
            headers: [],
            body: "",
            listUserMapConfig: {},
            paginationConfig: {},
            mappingMetadata: {},
            parameters: {},
        }
        this.importUsersMetadata.parameters = this.importUsersMetadata?.parameters || {}

        this.status = config?.status || "stopped"
        this.statusMessage = config?.statusMessage || ""
        this.lastRunStartedAt = config?.lastRunStartedAt || ""
        this.lastRunCompletedAt = config?.lastRunCompletedAt || null

        this.loading = false
        this.error = ""
    }
    setProvisioningType(type) {
        this.provisioningType = type
    }
    getRestConfig() {
        const config = {
            ...this.importUsersMetadata,
            provisioningType: this.provisioningType,
        }

        config.auth = {
            type: this.authType,
            bearerConfig: this.authConfigBearer,
            oauth2Config: this.authConfigOAuth2,
            basicConfig: this.authConfigBasic
        }

        return config
    }

    getRequiredCatalogIntegrationConfig() {
        return {
            parameters: this.importUsersMetadata?.parameters || {},
            auth: {
                type: this.authType,
                bearerConfig: this.authConfigBearer,
                oauth2Config: this.authConfigOAuth2,
                basicConfig: this.authConfigBasic
            }
        }
    }
    getRequiredCustomIntegrationConfig() {
        return {
            ...this.getRestConfig(),
            provisioningType: this.provisioningType,
            listUserMapConfig: this.importUsersMetadata.listUserMapConfig,
            paginationConfig: this.importUsersMetadata.paginationConfig,
        }
    }
    updateRestConfig(config = {}) {
        this.importUsersMetadata = {
            ...this.importUsersMetadata,
            ...config,
        }
        delete this.importUsersMetadata.auth
        this.authType = config?.auth?.type
        this.authConfigBearer = config.auth.bearerConfig
        this.authConfigOAuth2 = config.auth.oauth2Config
        this.authConfigBasic = config.auth.basicConfig
    }

    getConfigurationFields() {
        return this.importUsersMetadata?.fields || []
    }

    getConfigurationParameters() {
        return this.importUsersMetadata.parameters
    }
    getConfigurationParameter(key) {
        return this.importUsersMetadata.parameters[key] || {}
    }
    setConfigurationParameter(key, value) {
        this.importUsersMetadata.parameters[key].value = value
    }
    disconnect() {
        this.error = null;
        this.loading = true;

        // delete the provisioning config for the application
        return axios.delete(`/api/v1/application/${this.appId}/provision/delete`)
            .then(() => {
                this.isAuthorized = false
                this.enabled = false
                this.loading = false
            })
            .catch((error) => {
                this.error = error.response.data.message
                this.loading = false
            });
    }
    integrate(appId = this.appId) {
        this.loading = true

        if (this.provisioningType === "api") {
            const payload = {
                enabled: true,
                importUsers: true,
                authType: 'oauth2',
            }

            return axios.put(`/api/v1/application/${appId}/provision/api`, payload)
                .then( (r) => {
                    this.enabled = r.data?.enabled
                    this.isAuthorized = r.data?.isAuthorized
                    return r
                })
        } else {
            let config = {}
            switch (this.provisioningType) {
                case 'catalog':
                    config = this.getRequiredCatalogIntegrationConfig()
                    break
                case 'custom':
                    config = this.getRequiredCustomIntegrationConfig()
                    break;
                default :
            }

            return axios.post(`/api/v1/application/${appId}/${this.provisioningType}/integrate`,
                config
            ).finally(() => {
                this.loading = false
            })
        }
    }
    threeLeggedOauthRequired() {
        return (this.authConfigOAuth2?.clientSecret !== SECRET_VALUE &&
                this.authType === "oauth2" && this.authConfigOAuth2?.grantType === "authorizationCode") ||
                (this.provisioningType === "api")
    }
    connectOAuth2(appId = this.appId, createApp = false, callback = () => {}) {
        if (!this.threeLeggedOauthRequired()) {
            return callback()
        }
        this.loading = true

        return axios.post(`/api/v1/application/${appId}/oauth/request?type=${this.provisioningType}&createApp=${createApp}`, this.authConfigOAuth2).then((resp) => {
            const redirectUrl = resp?.data?.redirectUrl || ""
            if (redirectUrl) {
                const authWindow = window.open(redirectUrl, '_blank')

                if (!authWindow) {
                    throw new Error("We weren't able to open your application's authentication page, make sure your browser pop-ups are not blocked.")
                }

                // add a listener to the window to detect when it is closed
                const interval = setInterval(() => {
                    if (authWindow.closed && !this.error) {
                        clearInterval(interval)
                        this.loading = false
                        callback()
                    }
                }, 1000)
            }
        }).catch((error) => {
            const message = (error?.response?.data?.message || error?.message || "There was an error while connecting OAuth.")
            throw new Error(message)
        }).finally(() => {
            this.loading = false
        })
    }
    testConfig(appId = this.appId) {
        this.loading = true
        this.error = ""
        return axios.post(`/api/v1/application/${appId}/custom/integrate/import/test`,
            this.getRequiredCustomIntegrationConfig()
        ).then((resp) => {
            const testResponseCode = resp.data.responseCode
            if (testResponseCode >= 200 && testResponseCode < 300) {
                this.importUsersMetadata.listUserMapConfig = resp.data.listUserMapConfig || {}
                this.importUsersMetadata.paginationConfig = resp.data.paginationConfig || {}
            }
            this.importUsersMetadata.mappingMetadata = resp.data.mappingMetadata || {}
            return resp.data
        }).catch((error) => {
            this.error = error.response?.data?.message
            throw Promise.reject(this.error)
        }).finally(() => {
            this.loading = false
        })
    }
    enable() {
        this.loading = true
        axios.put(`/api/v1/application/${this.appId}/provision/${this.provisioningType}`, {
            ...this,
            enabled: true,
        }).then(() => {
            this.enabled = false
        }).finally(() => {
            this.loading = false
        })
    }
    disable() {
        this.loading = false
        axios.put(`/api/v1/application/${this.appId}/provision/${this.provisioningType}`, {
            ...this,
            enabled: false,
        }).then(() => {
            this.enabled = false
        }).finally(() => {
            this.loading = false
        })
    }
    startSync() {
        return axios.post(`/api/v1/application/${this.appId}/provision/sync`).then(() => {
            this.status = "started"
        })
    }
}

export default ProvisioningConfig