<template>
    <div class="grid-layout-column">
        <div v-if="edit">
            <InputLabelDescription :label="label" :optional="optional" :description="description"/>

            <v-autocomplete
                v-model="mySelectedUsers"
                v-on="$listeners"
                :items="userItems"
                :disabled="loading"
                :loading="loading"
                :rules="optional ? [] : rules"
                :placeholder="placeholder"
                outlined
                clearable
                :item-text="a => a.name + ' ' + a.lastName"
                item-value="id"
                return-object
                :multiple="multiple"
                dense
                :hide-details="hideDetails"
                :search-input="query"
                height="36"
                >

                <template v-if="!multiple" v-slot:selection="data">
                    <UserChip :user="data.item" @click:close="clearSingleUser" />
                </template>
                <template v-else v-slot:selection=""></template>

                <template v-if="!multiple" v-slot:item="data">
                    <div class="d-flex align-center">
                        <UserAvatar class="mr-2" :user="data.item" /> <UserName :user="data.item" showEmail />
                    </div>
                </template>
                <template v-else v-slot:item="{ item, on, attrs }">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-icon class="align-self-center">
                            <v-icon>
                                {{ mySelectedUsers.some(u => u.id === item.id) ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline' }}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <div class="d-flex align-center">
                                <UserAvatar class="mr-2" :user="item" /> <UserName :user="item" showEmail/>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-autocomplete>
        </div>

        <div class="grid-layout grid-basis-auto" v-if="multiple && mySelectedUsers.length">
            <UserChip v-for="user in mySelectedUsers" :user="user" :key="user.id" v-on="edit ? { 'click:close': () => deleteUser(user) } : {}"/>
        </div>
        <div v-else-if="!edit && !multiple && mySelectedUsers && mySelectedUsers.id">
            <UserChip :user="mySelectedUsers" :showEmail="showEmail"/>
        </div>
    </div>
</template>

<script>
    import InputLabelDescription from './InputLabelDescription.vue'
    import UserChip from './UserChip.vue'
    import UserAvatar from './UserAvatar.vue'
    import UserName from './UserName.vue'

    const inactiveStates = ["DEACTIVATED", "SUSPENDED", "SCHEDULED_FOR_DEACTIVATION", "SCHEDULED_FOR_SUSPENSION", "ARCHIVED", "ACCOUNT_LOCKED"]

    export default {
        name: "UserAutocomplete",
        components: {
            UserChip,
            UserAvatar,
            UserName,
            InputLabelDescription,
        },
        props: {
            selectedUsers: {
                required: true,
            },
            label: {
                type: String,
                default: "Select User",
            },
            optional: {
                type: Boolean,
                default: false,
            },
            description: {
                type: String,
                required: false,
                default: "",
            },
            multiple: {
                type: Boolean,
                default: false,
            },
            edit: {
                type: Boolean,
                required: false,
                default: true,
            },
            required: {
                type: Boolean,
                required: false,
                default: false,
            },
            filter: {
                type: String,
                required: false,
                default: "",
            },
            excludes: {
                type: Array,
                required: false,
                default: null,
            },
            rules: {
                type: Array,
                required: false,
                default: () => [],
            },
            hideDetails: {
                type: [String, Boolean],
                default: "auto",
                required: false,
            },
            showInactiveUsers: {
                type: Boolean,
                default: false,
                required: false,
            },
            showEmail: {
                type: Boolean,
                default: false,
            },
            selectFirst: {
                type: Boolean,
                default: false,
            },
            placeholder: {
                type: String,
                default: "",
            }
        },
        data() {
            return {
                // the full list of users
                users: [],
                // the list of users filtered by the query
                filteredUsers: [],
                // the user to search for
                query: null, 
                // loading indicator
                loading: false,
            }
        },
        methods: {
            getUsers() {
                if (this.users.length > 0) {
                    return
                }

                this.loading = true

                let url = '/api/v1/users'
                if (this.filter) {
                    url += '?filter=' + this.filter
                }

                this.$http.get(url).then((response) => {
                    let exclusionList = this.excludes || []
                    this.users = response.data.users.filter(u => !exclusionList.includes(u.email))
                    if (this.selectFirst && !this.selectedUsers.length && this.users.length) {
                        if (this.multiple) {
                            this.mySelectedUsers = [this.users[0]]
                        } else {
                            this.mySelectedUsers = this.users[0]
                        }
                    }
                    this.loading = false
                }).catch((error) => {
                    this.$root.$emit('toast', 'Unable to fetch users', 'error')
                    console.log(error)
                })
            },
            deleteUser(user) {
                this.mySelectedUsers = this.mySelectedUsers.filter(u => u.id !== user.id)
                this.$emit("change")
            },
            clearSingleUser() {
                this.mySelectedUsers = null
                this.$emit("change")
            }
        },
        mounted() {
            this.getUsers()
        },
        computed: {
            mySelectedUsers: {
                get() {
                    if (this.selectedUsers?.id || this.selectedUsers?.length) {
                        if (this.multiple) {
                            // get the full user object using the id's from the selectedUsers
                            return this.users.filter(u => this.selectedUsers.find(uu => uu.id === u.id))
                        } else {
                            return this.users.find(u => u.id === this.selectedUsers.id)
                        }
                    }

                    return this.multiple ? [] : {}
                },
                set(value) {
                    let v = value
                    this.query = ''
                    if (this.multiple) {
                        // when we're selecting multiple it doesn't make sense to allow the user to type in something custom
                        // (and pretty much would break whatever api wants the data)
                        v = value.filter(a => typeof a == "object")
                    }
                    this.$emit('update:selectedUsers', v || ( this.multiple ? [] : {}))
                }
            },
            userItems() {
                if (!this.showInactiveUsers) {
                    return this.users.filter(u => !inactiveStates.includes(u.status))
                }
                return this.users
            },
        }
    }

</script>